import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Sidebar from "../components/sidebar/sidebar"
import Saven from "../components/homescreen/homeScreenSaven"
import SidebarMobile from '../components/sidebar/mobile'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const SavenPage = ({data}) => {
  const breakpoints = useBreakpoint()

  return(
    <Layout color="#150e04">
    <SEO title="Saven" />
      <Saven 
        data={data.savenJson} 
        sidebar={
          !breakpoints.mobile ?
            <Sidebar white="isWhite" switchMenu="left"/>
          :
            <SidebarMobile brown/>
        }
      />
    </Layout>
  )
}

export default SavenPage


export const Json = graphql`
  query saven {
    savenJson{
      background{
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`