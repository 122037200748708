import React, { useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"
import Img from "gatsby-image"
import font from "../../fonts/fonts.module.scss"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import styled from "styled-components"
import Div100vh from 'react-div-100vh';
import { Button } from "../buttons"
import { device } from '../device'

import setaC from "../../images/setaC.svg"
import setaB from "../../images/setaB.svg"

const HomeScreen = ({ data, sidebar }) => {
  const {t} = useTranslation(['saven'])

  const [info, setInfo] = useState(0);
  const [reverseTransition, setReverseTransition] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchCurrent, setTouchCurrent] = useState(null);

  function touchMove() {
    if(touchCurrent < touchStart){
      if(info !== 3){
        setInfo(info+1);
        setReverseTransition(true);
      }
    }
    else if(touchCurrent > touchStart){
      if(info!==0) {
        setInfo(info-1);
        setReverseTransition(false);
      }
    }
  }

  useEffect(() => {
    if(isScrolling) {
      setTimeout(function() {
        setIsScrolling(false);
      }, 350);
    }
  }, [isScrolling]);

  const changeEvent = e => {
    let y = e.deltaY;
    if(y>0 && !isScrolling && info !== 3){
      setInfo(info+1);
      setReverseTransition(true)
      setIsScrolling(true);
    }
    else if(y<0 && !isScrolling && info!==0){
      setInfo(info-1);
      setReverseTransition(false)
      setIsScrolling(true)
    }
  }

  return(
    <StyledHome>
        {sidebar}
        <Img fluid={data.background.childImageSharp.fluid} style={{ height: `100%`, position: "absolute" }} imgStyle={{ objectFit: "cover" }} className="background"/>
        <>
        <div className='content'>
          <div className="info" onWheel={(e)=>changeEvent(e)} onTouchStart={(e)=>{setTouchStart(e.touches[0].clientY); setTouchCurrent(e.touches[0].clientY)}} onTouchMove={(e)=>(setTouchCurrent(e.touches[0].clientY))} onTouchEnd={touchMove}>
            <div className='info-content'>
              <h2 className={font.nSR + " alTitle icons"}>{t('title')}</h2>
              {t('contentTxt', {returnObjects: true}).map((data, i) => (
                <CSSTransition in={info===i} timeout={350} classNames={reverseTransition ? "switchModal" : "switchReverse"} unmountOnExit key={"savenContent" + i}>
                  <div className="infoContent">
                    <h3 className={font.nSR} dangerouslySetInnerHTML={{ __html: data[0]}}></h3>
                    <br/>
                    <h4 className={font.nSR} dangerouslySetInnerHTML={{ __html: data[1]}}></h4>
                  </div>
                </CSSTransition>
              ))}
              </div>
            </div>
          <div className="moreInfo">
            {info>0 &&
              <Button styleless onClick={() => {setReverseTransition(false); setInfo(info-1);}}>
                <div className="infoUp">
                  <img src={setaC} alt="Informações Anteriores"/>
                </div>
              </Button>
            }
            <br/>
            <Button styleless className={info<3 ? "visible" : "invisible"} onClick={() => {setReverseTransition(true); setInfo(info+1)}}>
              <div className="infoDown">
                <img src={setaB} alt="Próximas Informações"/>
              </div>
            </Button>
          </div>
        </div>
        </>
    </StyledHome>
  )
}

export default HomeScreen

const StyledHome = styled(Div100vh)`
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;

  .background{
    z-index: -1;
    position:absolute !important;
    width:100%;
    height:100%;
    min-height: 100%;
    top: 0;
      
    @media ${device.desktop}{
      width:unset;
      min-width: 84.35%;
      margin-left: 15.65%;
    }

    &:after{
      content:'';
      position: absolute;
      top:0;
      left:0;
      background:#362921;
      opacity:0.9;
      z-index:1;
      width:100%;
      height:100%;
    }
  }

  .content{

    @media ${device.desktop}{
      min-width: 84.35%;
      margin-left: 15.65%;
    }
  }

  .info{
    color: #fff;    
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: baseline;
    height: calc(100vh - 50px);
    margin-top: 50px;
    position: relative;
    align-items: center;
    padding:0 25px;
    min-height: 100vh;

    @media ${device.tabletM}{
      width: 75%;
      padding:unset;
      min-height:unset;
      max-width: 900px;
    }

    .alTitle{
      font-size:30px;
      position: absolute;
      top: 6vh;
      transform: translateY(-20%);
      letter-spacing:-0.5px;

      @media ${device.mobileXL}{
        top:15vh;
      }

      @media ${device.mobile400}{
        top:20vh;
      }

      @media ${device.tablet}{
        top:15vh;
      }

      @media ${device.tabletM}{
        top:5vh;
        font-size: 45px;
        margin-bottom:unset;
      }

      @media ${device.desktopM}{
        top:18vh;
      }
    }

    .infoContent{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left:0;
      padding:0 25px;

      @media ${device.tabletM}{
        padding:unset;
      }

      h3{
        font-size: 18px;
        letter-spacing: -0.5px;

        @media ${device.tablet}{
          font-size: 25px;
        }

        @media ${device.desktop}{
          font-size:30px;
        }
      }
    }
  }

  .moreInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 3%;
    right: 3%;

    @media ${device.desktop}{
      bottom: 50px;
      left: 57.825%;
      transform: translateX(-50%);
    }

    .invisible{
      visibility: hidden;
      user-select: none;
    }
  }

  /* react-transition-group */
  .switchReverse-enter {
    position: absolute;
    top: 0% !important;
    transform: translateY(-50%);
    opacity: 0;
  }
  .switchReverse-enter-active {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
    transition: all 350ms ease-out;
  }
  .switchReverse-exit {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
  }
  .switchReverse-exit-active{
    position: absolute;
    top: 100% !important;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 350ms ease-out;
  }

  .switchModal-enter{
    position: absolute;
    top: 100% !important;
    transform: translateY(-50%);
    opacity: 0;
  }
  .switchModal-enter-active{
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
    transition: all 350ms ease-out;
  }
  .switchModal-exit{
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
  }
  .switchModal-exit-active{
    position: absolute;
    top: 0% !important;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 350ms ease-out;
  }
`
